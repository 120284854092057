import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { LabeledProgressBar } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "labeledprogressbar"
    }}>{`LabeledProgressBar`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { LabeledProgressBar } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=2710%3A60'} storybook={'/?path=/story/progressbar-labeledprogressbar--playground'} vueStorybook={'/?path=/story/progressbar-labeledprogressbar--playground'} name={'LabeledProgressBar'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`LabeledProgressBar — это компонент для создания полосы прогресса с кнопкой отмены, подписью и описанием.`}</p>
    <p>{`Значение прогресса задается пропом `}<inlineCode parentName="p">{`value`}</inlineCode>{`. `}<inlineCode parentName="p">{`value`}</inlineCode>{` – число от `}<inlineCode parentName="p">{`0`}</inlineCode>{` до `}<inlineCode parentName="p">{`100`}</inlineCode>{`.
Любое число меньше `}<inlineCode parentName="p">{`0`}</inlineCode>{` считается равным `}<inlineCode parentName="p">{`0`}</inlineCode>{`, любое число больше `}<inlineCode parentName="p">{`100`}</inlineCode>{` - равным `}<inlineCode parentName="p">{`100`}</inlineCode>{`.`}</p>
    <p>{`Если прогресс неизвестен, используйте состояние `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{`. В данном состоянии показывается анимация бесконечной загрузки.`}</p>
    <Playground __position={1} __code={'<LabeledProgressBar\n  value={50}\n  label=\"Label\"\n  description=\"Description\"\n  cancelable={true}\n/>'} __scope={{
      props,
      DefaultLayout,
      useState,
      LabeledProgressBar,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <LabeledProgressBar value={50} label="Label" description="Description" cancelable={true} mdxType="LabeledProgressBar" />
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node="?node-id=10232:33048" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "кнопка-отмены"
    }}>{`Кнопка отмены`}</h3>
    <p>{`У компонента есть кнопка отмены. Для её добавления передайте проп `}<inlineCode parentName="p">{`cancelable`}</inlineCode>{` и обработчик нажатия в проп `}<inlineCode parentName="p">{`onCancel`}</inlineCode>{`.`}</p>
    <Playground __position={3} __code={'() => {\n  const [loading, setLoading] = useState(true)\n  return (\n    <LabeledProgressBar\n      indeterminate={loading}\n      label=\"Label\"\n      description=\"Description\"\n      cancelable={loading}\n      onCancel={() => setLoading(false)}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      LabeledProgressBar,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [loading, setLoading] = useState(true);
        return <LabeledProgressBar indeterminate={loading} label="Label" description="Description" cancelable={loading} onCancel={() => setLoading(false)} mdxType="LabeledProgressBar" />;
      }}
    </Playground>
    <h3 {...{
      "id": "подпись-и-описание"
    }}>{`Подпись и описание`}</h3>
    <p>{`Для задания подписи и описания передайте в пропы `}<inlineCode parentName="p">{`label`}</inlineCode>{` и `}<inlineCode parentName="p">{`description`}</inlineCode>{` необходимые значения`}</p>
    <Playground __position={4} __code={'<LabeledProgressBar value={50} label=\"Label\" description=\"Description\" />'} __scope={{
      props,
      DefaultLayout,
      useState,
      LabeledProgressBar,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <LabeledProgressBar value={50} label="Label" description="Description" mdxType="LabeledProgressBar" />
    </Playground>
    <h3 {...{
      "id": "размер"
    }}>{`Размер`}</h3>
    <p>{`У LabeledProgressBar два размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{` и `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. По умолчанию `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. Передайте в проп size нужный`}</p>
    <Playground __position={5} __code={'<LabeledProgressBar\n  value={50}\n  label=\"Small\"\n  size=\"small\"\n  description={\'Small size\'}\n/>\n<LabeledProgressBar value={50} label=\"Medium\" description={\'Medium size\'} />'} __scope={{
      props,
      DefaultLayout,
      useState,
      LabeledProgressBar,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <LabeledProgressBar value={50} label="Small" size="small" description={'Small size'} mdxType="LabeledProgressBar" />
  <LabeledProgressBar value={50} label="Medium" description={'Medium size'} mdxType="LabeledProgressBar" />
    </Playground>
    <h3 {...{
      "id": "неопределенное-состояние"
    }}>{`Неопределенное состояние`}</h3>
    <p>{`Если прогресс неизвестен, используйте `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{` состояние. В данном состоянии показывается бесконечная загрузка`}</p>
    <Playground __position={6} __code={'() => {\n  const [loading, setLoading] = useState(true)\n  return (\n    <LabeledProgressBar\n      indeterminate={loading}\n      label=\"Label\"\n      description=\"Description\"\n      cancelable={loading}\n      onCancel={() => setLoading(false)}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      LabeledProgressBar,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [loading, setLoading] = useState(true);
        return <LabeledProgressBar indeterminate={loading} label="Label" description="Description" cancelable={loading} onCancel={() => setLoading(false)} mdxType="LabeledProgressBar" />;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={LabeledProgressBar} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      